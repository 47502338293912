import { FilterTypes } from '@scayle/storefront-api'
import type {
  AttributeWithValuesFilter,
  AttributeWithBooleanValueFilter,
  Variant,
  Product,
  FetchProductsByCategoryResponse,
} from '@scayle/storefront-nuxt'
import type { SbBlokData } from '@storyblok/vue'
import type { StoryblokTrackingContent } from '~/@types/storyblok'
import type { Sbasset, SbImage, SbPage } from '~/storyblok/types/storyblok.gen'

export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null
}

export function isFimObject<T = Record<PropertyKey, unknown>>(
  input: any,
): input is T {
  return input && typeof input === 'object'
}

export function isAttributeWithValuesFilter(
  attribute: Pick<
    AttributeWithValuesFilter | AttributeWithBooleanValueFilter,
    'type'
  >,
): attribute is AttributeWithValuesFilter {
  return attribute.type === FilterTypes.ATTRIBUTES
}

export function isAttributeWithBooleanValuesFilter(
  attribute: AttributeWithValuesFilter | AttributeWithBooleanValueFilter,
): attribute is AttributeWithBooleanValueFilter {
  return attribute.type === 'boolean'
}

export function isProduct(
  input: Product | Variant | undefined,
): input is Product {
  return !!input && 'variants' in input && Array.isArray(input.variants)
}

export function isVariant(
  input: Product | Variant | undefined,
): input is Variant {
  return !!input && !isProduct(input)
}

/**
 * Matches any [primitive value](https://developer.mozilla.org/en-US/docs/Glossary/Primitive).
 */
export type Primitive =
  | null
  | undefined
  | string
  | number
  | boolean
  | symbol
  | bigint

/**
 * @todo seems unused, isPromitive is already defined in radash
 */
export function isFimPrimitive(x: any): x is Primitive {
  return [
    'null',
    'undefined',
    'string',
    'number',
    'bigint',
    'boolean',
    'symbol',
  ].includes(typeof x)
}
export type Pagination = FetchProductsByCategoryResponse['pagination']

export type PaginatedResponse<T = { [k: string]: unknown }> = {
  pagination: Pagination
} & T

export interface ProductsEndpointResponse {
  entities: Product[]
  pagination: FetchProductsByCategoryResponse['pagination']
}

export const isProductsResponse = (
  response: any,
): response is ProductsEndpointResponse => {
  return Array.isArray(response?.entities) && isPaginatedResponse(response)
}

export const isPaginatedResponse = (
  response: any,
): response is PaginatedResponse => {
  return isFimObject(response?.pagination)
}

export const isAttributeFilter = (
  input: any,
): input is AttributeWithValuesFilter | AttributeWithBooleanValueFilter => {
  return (
    isFimObject(input) &&
    typeof input?.key === 'string' &&
    typeof input?.type === 'string' &&
    ((input.type.includes('attributes') && Array.isArray(input.values)) ||
      (input.type.includes('boolean') && typeof input.value === 'boolean'))
  )
}

export const isStoryblokTrackingContent = (
  input: any,
): input is StoryblokTrackingContent => {
  return !!input?.promotion_name && !!input?.creative_name
}

export const isSbBlokData = (input: any): input is SbBlokData =>
  isFimObject(input) && !!input._uid && !!input.component

export const isSbAsset = (input: any): input is Sbasset =>
  isFimObject(input) && input.fieldtype === 'asset' && !!input.filename

export const isSbImage = (input: any): input is SbImage =>
  isSbBlokData(input) && ['Image', 'CmsImage'].includes(input.component ?? '')

export const isSbPage = (input: any): input is SbPage =>
  isSbBlokData(input) && input.component === 'Page'

// export const isObjectFit = (input: any): input is ObjectFit => {
//   return (
//     typeof input === 'string' &&
//     // don't allowing "none" here as it's technically working differently as expected
//     ['contain', 'cover', 'fill', 'scale-down'].includes(input)
//   )
// }

// export const isObjectPosition = (input: any): input is ObjectPosition => {
//   return (
//     typeof input === 'string' &&
//     [
//       'center',
//       'top',
//       'top-right',
//       'right',
//       'bottom-right',
//       'bottom',
//       'bottom-left',
//       'left',
//       'top-left',
//     ].includes(input)
//   )
// }
